import { ErrorToast } from "../Util";
import Axios from "../WebClient";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
};

export const LoginAction = (
  enrollment,
  password,
  setError,
  handleLoginSuccess
) => {
  Axios.post("/auth/login", {
    enrollment,
    password,
  })
    .then((res) => {
      console.log("Login response: ", res.data);
      handleLoginSuccess(res.data.data);
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const SignUpMonazzam = (
  values,
  setError,
  setLoading,
  setSuccessMessage,
  handleSignupSuccess
) => {
  Axios.post("/auth/register-monazam", values)
    .then((res) => {
      console.log("Signup response: ", res.data);
      setSuccessMessage("Signup successful!");
      setLoading(false);
      handleSignupSuccess();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      setLoading(false);
    });
};

export const SignUpHGO = (
  values,
  setError,
  setLoading,
  handleSignupSuccess,
  setSuccessMessage
) => {
  Axios.post("/auth/register-hgo", values)
    .then((res) => {
      console.log("Signup response: ", res.data);
      setSuccessMessage(
        "Signup successful! Your account is waiting for approval."
      );
      setLoading(false);
      handleSignupSuccess();
    })
    .catch((err) => {
      setLoading(false);
      ErrorToast(err?.response?.data?.message);
      console.error("Error during signup/approval:", err);
    });
};

export const GetRegisteredHGOs = (setError, setHGOs) => {
  Axios.get("/auth/register-hgos", config)
    .then((res) => {
      console.log("Login response: ", res.data);
      setHGOs(res.data.data);
    })
    .catch((err) => {
      setError(err.response.data.message);
      console.log("Error: ", err);
    });
};
