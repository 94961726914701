import React from "react";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

const AccumulativeBtn = () => {
  return (
    <div className="mb-3">
      <Link to="accumulative-request" style={{ textDecoration: "none" }}>
        <button
          className="btn label-text w-100 d-flex justify-content-between align-items-center"
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
          }}
        >
          Accumulative Request <FaPlus className="plus-icon" />
        </button>
      </Link>
    </div>
  );
};

export default AccumulativeBtn;
