import React, { useEffect, useState } from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import { GetOpapLedgerTransactions } from "../../../../../Actions/LedgerActions";
import { formatAmount } from "../../../../../Util";

const RenderUser = ({ user }) => {
  useEffect(() => {
    console.log("Rendering user: ", user);
  }, []);
  const [open, setOpen] = useState(false);
  return (
    <div className="dropdown-section mt-4">
      <div className="flex justify-between items-center">
        {/* {rollId === "monazam" && (
          <img
            src="/assets/editsymbol.png"
            alt="Edit Symbol"
            className="img-fluid mr-2 edit-icon"
          />
        )} */}
        <span className="hgo-text">{user.name}</span>
        <button
          className="text-lg plus-minus-btn"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? "-" : "+"}
        </button>
      </div>
      {open && (
        <table className="table mt-2">
          <tbody>
            {user.transactions.map((transaction) => {
              return (
                <>
                  <tr>
                    <td className="tabledata-txt">
                      {transaction.createdAt?.split("T")[0]}
                    </td>
                    <td className="tabledata-txt">{transaction.narration}</td>
                    <td className="tabledata-txt">
                      {transaction.type === "opap"
                        ? formatAmount(transaction.creditSAR)
                        : ""}
                    </td>
                    <td className="tabledata-txt">
                      {transaction.type === "opap"
                        ? ""
                        : formatAmount(transaction.creditSAR)}
                    </td>
                    <td className="tabledata-txt">
                      {transaction.type === "ehajj"
                        ? formatAmount(
                            parseInt(transaction.sarOpapLedgerAmount) +
                              parseInt(transaction.bankCreditSAR)
                          )
                        : formatAmount(transaction.sarOpapLedgerAmount)}
                    </td>
                  </tr>
                  {transaction.type === "ehajj" ? (
                    <tr>
                      <td className="tabledata-txt">
                        {transaction.createdAt?.split("T")[0]}
                      </td>
                      <td className="tabledata-txt">Bank Charges</td>
                      <td className="tabledata-txt"></td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.bankCreditSAR)}
                      </td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.sarOpapLedgerAmount)}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const Table = ({ setAmount }) => {
  const [showHGO1, setShowHGO1] = useState(false);
  const [showHGO2, setShowHGO2] = useState(false);

  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const toggleHGO1 = () => setShowHGO1(!showHGO1);
  const toggleHGO2 = () => setShowHGO2(!showHGO2);
  const { rollId } = useRollId(); // Get rollId from context

  const fetchTransactions = () => {
    GetOpapLedgerTransactions(setTransactions, setUsers, setAmount);
  };

  useEffect(() => {
    fetchTransactions();
  }, []);
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-sm">
          <thead>
            <tr>
              <th className="tableheader-txt">Date</th>
              <th className="tableheader-txt">Narration</th>
              <th className="tableheader-txt">Debit</th>
              <th className="tableheader-txt">Credit</th>
              <th className="tableheader-txt">Balance</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((transaction) => {
              return (
                <>
                  <tr>
                    <td className="tabledata-txt">
                      {transaction.createdAt?.split("T")[0]}
                    </td>
                    <td className="tabledata-txt">{transaction.narration}</td>
                    <td className="tabledata-txt">
                      {transaction.type === "opap"
                        ? formatAmount(transaction.creditSAR)
                        : ""}
                    </td>
                    <td className="tabledata-txt">
                      {transaction.type === "opap"
                        ? ""
                        : formatAmount(transaction.credit)}
                    </td>
                    <td className="tabledata-txt">
                      {!!transaction.accumulativeRequestId
                        ? formatAmount(
                            parseInt(transaction.sarOpapLedgerAmount) +
                              parseInt(transaction.bankCredit)
                          )
                        : formatAmount(transaction.sarOpapLedgerAmount)}
                    </td>
                  </tr>
                  {!!transaction.accumulativeRequestId ? (
                    <tr>
                      <td className="tabledata-txt">
                        {transaction.createdAt?.split("T")[0]}
                      </td>
                      <td className="tabledata-txt">Bank Charges</td>
                      <td className="tabledata-txt"></td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.bankCredit)}
                      </td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.sarOpapLedgerAmount)}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
        {users.map((tuser) => {
          return (
            <>
              <RenderUser user={tuser} />
              <hr />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
