import Axios from "../WebClient";

export const GetRegisterCompanies = (setCompanies) => {
  Axios.get("/company/register-monazams")
    .then((res) => {
      console.log("Register Monazam response: ", res.data);
      setCompanies(res.data.data);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};

export const GetUnRegisterCompanies = (setCompanies) => {
  Axios.get("/company/unregister-monazams")
    .then((res) => {
      console.log("Login response: ", res.data);
      setCompanies(res.data.data);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};
