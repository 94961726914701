import { toast } from "react-toastify";

export function formatAmount(amount) {
  if (!!amount) {
    if (typeof amount === "number") {
      console.log("Returning from num: ", amount.toLocaleString());
      return amount?.toLocaleString();
    } else {
      console.log(
        "Returning from string: ",
        parseInt(amount)?.toLocaleString()
      );
      return parseInt(amount)?.toLocaleString();
    }
  } else return "";
}

export function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function SuccessToast(msg) {
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
}

export function WarningToast(msg) {
  toast.warn(msg, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
}

export function ErrorToast(msg) {
  toast.error(msg, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
}
