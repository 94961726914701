import React, { useEffect, useState } from "react";
import { GetRegisteredHGOs } from "../../../../Actions/AuthorizationActions";
import { CreateAccumulativeRequest } from "../../../../Actions/LedgerActions";
import { getCurrentDate, SuccessToast } from "../../../../Util";

const AccmulativeRequestContainer = () => {
  const [values, setValues] = useState({
    bankingFee: null,
    totalAmount: null,
    narration: "",
  });

  const [type, setType] = useState("MonazamTransfer");

  const [currency, setCurrency] = useState("PKR");

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const [requests, setRequests] = useState([]);

  const [hgos, setHGOs] = useState([]);

  const [rows, setRows] = useState([
    {
      id: "",
      num: 0,
      amount: null,
    },
  ]);

  const addNewRow = () => {
    setRows([
      ...rows,
      {
        id: "",
        num: rows.length,
        amount: null,
      },
    ]);
  };

  const handleCreate = () => {
    SuccessToast("Accumulative request created successfully.");
    setValues({
      bankingFee: undefined,
      totalAmount: undefined,
      narration: "",
    });
    setRows([
      {
        id: "",
        num: 0,
        amount: null,
      },
    ]);
  };

  const handleSubmit = () => {
    CreateAccumulativeRequest(
      values,
      rows,
      type,
      new Date(),
      currency,
      setLoading,
      setError,
      handleCreate
    );
  };

  function loadHGOs() {
    GetRegisteredHGOs(setError, setHGOs);
  }

  useEffect(() => {
    loadHGOs();
  }, []);

  return (
    <>
      <div className="bgWhite">Transfer</div>
      <div className="heading date">
        <h4>Accumulative Request</h4>
        <div className="d-flex flex-column">
          <label>Date</label>
          <input type="date" value={getCurrentDate()} readOnly />
        </div>
      </div>
      <div className="container">
        <div className="form">
          <div className="row">
            <div className="column">
              <label>Type</label>
              <select
                value={type}
                onChange={(e, newValue) => {
                  setType(e.target.value);
                }}
              >
                <option value="MonazamTransfer">
                  Monazam to Opap Transfer
                </option>
                <option value="OpapTransfer">Opap to eHajj Transfer</option>
              </select>
            </div>
            <div className="column">
              <label>Currency</label>
              <select
                value={currency}
                onChange={(e, newValue) => {
                  setCurrency(e.target.value);
                }}
              >
                <option value="PKR">PKR</option>
                <option value="USD">USD</option>
                <option value="SAR">SAR</option>
              </select>
            </div>
          </div>
          {rows.map((row) => (
            <div className="row" key={row.id}>
              <div className="column">
                <label>HGO Company</label>
                <select
                  value={row.id}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      let updatedRows = rows.map((trow) => {
                        if (trow.num === row.num) {
                          trow.id = e.target.value;
                        }
                        return trow;
                      });
                      console.log("Updated rows: ", updatedRows);
                      setRows(updatedRows);
                    }
                  }}
                >
                  <option>Search</option>
                  {hgos
                    .filter((hgo) => {
                      let reqFound = rows.filter(
                        (trow) => trow.id === hgo.id && row.id !== hgo.id
                      );
                      return !reqFound.length;
                    })
                    .map((hgo) => {
                      return <option value={hgo?.id}>{hgo?.name}</option>;
                    })}
                </select>
              </div>
              <div className="column">
                <label>Amount</label>
                <input
                  type="number"
                  placeholder="Enter amount"
                  value={row.amount}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      let total = 0;
                      let updatedRows = rows.map((trow) => {
                        if (trow.num === row.num) {
                          trow.amount = parseInt(e.target.value);
                          total += parseInt(e.target.value);
                        } else {
                          total += trow.amount;
                        }
                        return trow;
                      });
                      setValues({ ...values, totalAmount: total });
                      setRows(updatedRows);
                    }
                  }}
                />
              </div>
            </div>
          ))}
          <button className="add-btn" onClick={addNewRow}>
            Add +
          </button>
          <div
            className="d-flex justify flex-column gap-3"
            style={{ width: "30%", marginLeft: "auto" }}
          >
            <div className="d-flex flex-row align-items-center gap-3">
              <label style={{ width: "100px" }}>Narration</label>
              <input
                type="text"
                value={values.narration}
                placeholder="Narration"
                onChange={(e) => {
                  setValues({
                    ...values,
                    narration: e.target.value,
                  });
                }}
                className="form-control"
                style={{ width: "200px" }}
              />
            </div>
            <div className="d-flex flex-row align-items-center gap-3">
              <label style={{ width: "100px" }}>Banking Fee</label>
              <input
                type="number"
                value={!!values.bankingFee ? values.bankingFee : 0}
                onChange={(e) => {
                  setValues({
                    ...values,
                    bankingFee: parseInt(e.target.value),
                  });
                }}
                placeholder="Banking Fee"
                className="form-control"
                style={{ width: "200px" }}
              />
            </div>
            <div className="d-flex flex-row align-items-center gap-3">
              <label style={{ width: "100px" }}>Total</label>
              <input
                type="number"
                value={!!values.totalAmount ? values.totalAmount : 0}
                placeholder="Total"
                onChange={(e) => {
                  setValues({
                    ...values,
                    totalAmount: parseInt(e.target.value),
                  });
                }}
                className="form-control"
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <button className="submit-btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default AccmulativeRequestContainer;
