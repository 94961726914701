import React, { useEffect, useState } from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import axios from "axios";
import {
  ApproveOutcomingRequests,
  GetOutgoingHGORequests,
  GetOutgoingMonazamRequests,
  GetOutgoingRequests,
  RejectOutcomingRequests,
} from "../../../../../Actions/RequestActions";
import { formatAmount, SuccessToast } from "../../../../../Util";

const Table = ({ reload }) => {
  const { rollId } = useRollId(); // Use the context to get rollId
  const isMonazam = rollId === "1";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleApprove = () => {
    SuccessToast("Request approved successfully.");
    fetchOutgoingRequests();
  };

  const handleReject = () => {
    SuccessToast("Request rejected successfully.");
    fetchOutgoingRequests();
  };

  const approveRequest = (request) => {
    ApproveOutcomingRequests(request.id, setError, handleApprove);
  };

  const rejectRequest = (request) => {
    RejectOutcomingRequests(request.id, setError, handleReject);
  };

  // Function to fetch data from the outgoing request API
  const fetchOutgoingRequests = async (limit = 7, offset = 0) => {
    if (isMonazam) {
      GetOutgoingMonazamRequests(setData, setLoading, setError);
    } else {
      GetOutgoingHGORequests(setData, setLoading, setError);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchOutgoingRequests();
  }, [reload, rollId]);

  if (loading) return <p>Loading...</p>;
  else if (!!error) return <p>{error}</p>;
  else
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {isMonazam && <th className="tableheader-txt">HGO Name</th>}
              <th className="tableheader-txt">Date</th>
              <th className="tableheader-txt">Narration</th>
              <th className="tableheader-txt">Type</th>
              <th className="tableheader-txt">Currency</th>
              <th className="tableheader-txt">Amount</th>
              {isMonazam && <th className="tableheader-txt">Actions</th>}
              {!isMonazam && <th className="tableheader-txt">Status</th>}
            </tr>
          </thead>
          <tbody>
            {data.map((request, index) => (
              <tr key={index}>
                {isMonazam && (
                  <td className="tabledata-txt">
                    {request.hgoUser.name || "N/A"}
                  </td>
                )}
                <td className="tabledata-txt">
                  {new Date(request.date).toISOString().split("T")[0]}
                </td>
                <td className="tabledata-txt">{request.narration}</td>
                <td className="tabledata-txt">{request.type}</td>
                <td className="tabledata-txt">{request.currency}</td>
                <td className="tabledata-txt">
                  {formatAmount(request.amount)}
                </td>
                {isMonazam && request.status === "pending" ? (
                  <td className="tabledata-txt">
                    <button
                      className="btn btn-sm approved-btn me-2"
                      onClick={() => {
                        approveRequest(request);
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-sm rejectbtn"
                      onClick={() => {
                        rejectRequest(request);
                      }}
                    >
                      Reject
                    </button>
                  </td>
                ) : isMonazam ? (
                  <td className="tabledata-txt">
                    <button className="btn btn-sm approved-btn me-2" disabled>
                      {request.status === "approved" ? "Approved" : "Rejected"}
                    </button>
                  </td>
                ) : (
                  <td className="tabledata-txt">{request.status}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};

export default Table;
