import React, { useEffect, useState } from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import { GetMonazamLedgerTransactions } from "../../../../../Actions/LedgerActions";
import { formatAmount } from "../../../../../Util";

const RenderUser = ({ user }) => {
  useEffect(() => {
    console.log("Rendering user: ", user);
  }, []);
  const [open, setOpen] = useState(false);
  return (
    <div className="dropdown-section mt-4">
      <div className="flex justify-between items-center">
        {/* {rollId === "monazam" && (
          <img
            src="/assets/editsymbol.png"
            alt="Edit Symbol"
            className="img-fluid mr-2 edit-icon"
          />
        )} */}
        <span className="hgo-text">{user.name}</span>
        <button
          className="text-lg plus-minus-btn"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? "-" : "+"}
        </button>
      </div>
      {open && (
        <table className="table mt-2">
          <tbody>
            {user.transactions.map((transaction) => {
              return (
                <>
                  <tr>
                    <td className="tabledata-txt">
                      {transaction.createdAt?.split("T")[0]}
                    </td>
                    <td className="tabledata-txt">{transaction.narration}</td>
                    <td className="tabledata-txt">
                      {formatAmount(transaction.debit)}
                    </td>
                    <td className="tabledata-txt">
                      {formatAmount(transaction.credit)}
                    </td>
                    <td className="tabledata-txt">
                      {transaction.type === "opap"
                        ? formatAmount(
                            parseInt(transaction.balance) +
                              parseInt(transaction.bankCredit)
                          )
                        : formatAmount(transaction.balance)}
                    </td>
                  </tr>
                  {transaction.type === "opap" ? (
                    <tr>
                      <td className="tabledata-txt">
                        {transaction.createdAt?.split("T")[0]}
                      </td>
                      <td className="tabledata-txt">Bank Charges</td>
                      <td className="tabledata-txt"></td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.bankCredit)}
                      </td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.balance)}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const Table = ({ setAmount, currency }) => {
  const [showHGO1, setShowHGO1] = useState(false);
  const [showHGO2, setShowHGO2] = useState(false);

  const toggleHGO1 = () => setShowHGO1(!showHGO1);
  const toggleHGO2 = () => setShowHGO2(!showHGO2);

  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);

  const { rollId } = useRollId(); // Get rollId directly from context

  const fetchTransactions = () => {
    GetMonazamLedgerTransactions(
      setTransactions,
      setUsers,
      setAmount,
      currency
    );
  };

  useEffect(() => {
    fetchTransactions();
  }, [currency]);

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-sm">
          <thead>
            <tr>
              <th className="tableheader-txt">Date</th>
              <th className="tableheader-txt">Narration</th>
              <th className="tableheader-txt">Debit</th>
              <th className="tableheader-txt">Credit</th>
              <th className="tableheader-txt">Balance</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((transaction) => {
              return (
                <>
                  <tr>
                    <td className="tabledata-txt">
                      {transaction.createdAt?.split("T")[0]}
                    </td>
                    <td className="tabledata-txt">{transaction.narration}</td>
                    <td className="tabledata-txt">
                      {formatAmount(transaction.debit)}
                    </td>
                    <td className="tabledata-txt">
                      {formatAmount(transaction.credit)}
                    </td>
                    <td className="tabledata-txt">
                      {!!transaction.accumulativeRequestId
                        ? formatAmount(
                            parseInt(transaction.pkrMonazamLedgerAmount) +
                              parseInt(transaction.bankCredit)
                          )
                        : formatAmount(
                            !!transaction.pkrMonazamLedgerAmount
                              ? transaction.pkrMonazamLedgerAmount
                              : transaction.balance
                          )}
                    </td>
                  </tr>
                  {!!transaction.accumulativeRequestId ? (
                    <tr>
                      <td className="tabledata-txt">
                        {transaction.createdAt?.split("T")[0]}
                      </td>
                      <td className="tabledata-txt">Bank Charges</td>
                      <td className="tabledata-txt"></td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.bankCredit)}
                      </td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.pkrMonazamLedgerAmount)}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
            {/* <tr>
              <td className="tabledata-txt">02-01-2024</td>
              <td className="tabledata-txt">Transfer</td>
              <td className="tabledata-txt">PKR</td>
              <td className="tabledata-txt">5000</td>
              <td className="tabledata-txt">V002</td>
              <td className="tabledata-txt">Pending</td>
              {rollId === "1" && (
                <td className="tabledata-txt">
                  <button className="btn btn-sm me-2 btn-compact">
                    Approve
                  </button>
                  <button className="btn btn-sm btn-compactreject">
                    Reject
                  </button>
                </td>
              )}
            </tr> */}
          </tbody>
        </table>
        {users.map((tuser) => {
          return (
            <>
              <RenderUser user={tuser} />
              <hr />
            </>
          );
        })}
        {/* <div className="dropdown-section mt-4">
          <div className="flex justify-between items-center">
            {rollId === "monazam" && (
              <img
                src="/assets/editsymbol.png"
                alt="Edit Symbol"
                className="img-fluid mr-2 edit-icon"
              />
            )}
            <span className="hgo-text">HGO2</span>
            <button className="text-lg plus-minus-btn" onClick={toggleHGO2}>
              {showHGO2 ? "-" : "+"}
            </button>
          </div>
          {showHGO2 && (
            <table className="table mt-2">
              <thead>
                <tr>
                  <th className="tableheader-txt">Date</th>
                  <th className="tableheader-txt">Narration</th>
                  <th className="tableheader-txt">Currency</th>
                  <th className="tableheader-txt">Amount</th>
                  <th className="tableheader-txt">VoucherID</th>
                  <th className="tableheader-txt">Status</th>
                  {rollId === "monazam" && (
                    <th className="tableheader-txt">Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tabledata-txt">05-01-2024</td>
                  <td className="tabledata-txt">Payment</td>
                  <td className="tabledata-txt">USD</td>
                  <td className="tabledata-txt">1200</td>
                  <td className="tabledata-txt">V005</td>
                  <td className="tabledata-txt">Completed</td>
                  {rollId === "monazam" && (
                    <td className="tabledata-txt">
                      <button className="btn btn-sm btn-compact me-2">
                        Approve
                      </button>
                      <button className="btn btn-sm btn-compactreject">
                        Reject
                      </button>
                    </td>
                  )}
                </tr>
                <tr>
                  <td className="tabledata-txt">06-01-2024</td>
                  <td className="tabledata-txt">Transfer</td>
                  <td className="tabledata-txt">PKR</td>
                  <td className="tabledata-txt">2500</td>
                  <td className="tabledata-txt">V006</td>
                  <td className="tabledata-txt">Pending</td>
                  {rollId === "monazam" && (
                    <td className="tabledata-txt">
                      <button className="btn btn-sm btn-compact me-2">
                        Approve
                      </button>
                      <button className="btn btn-sm btn-compactreject">
                        Reject
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Table;
