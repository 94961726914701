import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RollIdProvider } from "../common_components/RollIdContext";
import AccSidebar from "../common_components/Accumulativesidebar";
import { FaBars, FaTimes } from "react-icons/fa";
import RollIdPrompt from "../common_components/RollIdPrompt";
import MainContent from "../incoming/IncomingComponents/MainContent";

const AccumulativeRequest = () => {
  const navigate = useNavigate();
  const { userType } = useParams();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [rollId, setRollId] = useState("");

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleRollIdSubmit = (id) => {
    setRollId(id);
  };

  useEffect(() => {
    if (!userType || (userType !== "hgo" && userType !== "monazam")) {
      // Redirect to an error page or a default page if the userType is invalid
      navigate("/");
    }
  }, [userType, navigate]);

  return (
    <RollIdProvider>
      <div className="container-fluid  dashboard-layout m-0 p-0">
        <AccSidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          rollId={rollId}
        />
        <div className={`main-content ${isSidebarOpen ? "blurred" : ""}`}>
          <div className="d-md-none d-flex justify-content-between align-items-center sidebar-logo-bg py-3 px-1">
            <button className="btn" onClick={toggleSidebar}>
              {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
            <img
              src="/assets/logo.png"
              alt="Logo"
              className="img-fluid logo pe-5"
              style={{ height: "40px" }}
            />
          </div>
          <RollIdPrompt onRollIdSubmit={handleRollIdSubmit} />
          <MainContent rollId={rollId} accumulativeRequest />
        </div>
      </div>
    </RollIdProvider>
  );
};

export default AccumulativeRequest;
