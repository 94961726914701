import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { CreateOutgoingRequests } from "../../../../Actions/RequestActions";
import { SuccessToast, WarningToast } from "../../../../Util";

const defaultValues = {
  date: "",
  narration: "",
  amount: "",
  currency: "",
  categoryType: "",
};

const OutgoingRequest = ({ isOpen, onClose }) => {
  // State to store form data
  const [formData, setFormData] = useState(defaultValues);

  // State to store error or success messages
  const [message, setMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: parseInt(value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCreate = () => {
    SuccessToast("Outgoing request created successfully!");
    setFormData(defaultValues);
    onClose();
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { date, narration, currency, categoryType, amount } = formData;
    if (!date || !narration || !currency || !categoryType || !amount) {
      WarningToast("All fields are required.");
      return;
    }
    CreateOutgoingRequests(
      { ...formData, status: "pending", type: "outgoing" },
      setMessage,
      handleCreate
    );
  };

  if (!isOpen) return <></>;
  else
    return (
      <div className="modal d-block modal-blur-bg">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="px-5 modal-content">
            <button
              className="btn modal-label-txt modal-btn-bg btn-success position-absolute top-0 end-0 m-3"
              onClick={onClose}
            >
              Close <FaTimes className="me-1" />
            </button>

            <div className="modal-header">
              <h2 className="modal-title mx-auto modal-heading-txt">
                Outgoing Request
              </h2>
            </div>

            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <label className="modal-label-txt">Date</label>
                  <input
                    type="date"
                    className="form-control modal-input-txt mb-3"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                  />

                  <label className="modal-label-txt">Narration</label>
                  <textarea
                    className="form-control modal-input-txt mb-3"
                    rows="3"
                    placeholder="comments"
                    name="narration"
                    value={formData.narration}
                    onChange={handleChange}
                  ></textarea>

                  <label className="modal-label-txt">Amount</label>
                  <input
                    type="number"
                    className="form-control modal-input-txt"
                    placeholder="20,500"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <label className="modal-label-txt">Currency</label>
                  <select
                    className="form-select modal-input-txt mb-3"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="PKR">PKR</option>
                    <option value="USD">USD</option>
                    <option value="SAR">SAR</option>
                  </select>

                  <label className="modal-label-txt">Type</label>
                  <select
                    className="form-select modal-input-txt"
                    name="categoryType"
                    value={formData.categoryType}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="MonazamTransfer">
                      Monazam to OPAP transfer
                    </option>
                    <option value="OpapTransfer">
                      OPAP to E Hajj transfer
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="modal-footer justify-content-center">
              <button
                className="btn w-50 modal-label-txt modal-btn-bg"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>

            {message && (
              <div
                className={`alert mt-3 ${
                  message.type === "success" ? "alert-success" : "alert-danger"
                }`}
              >
                {message.text}
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default OutgoingRequest;
