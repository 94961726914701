import { ErrorToast } from "../Util";
import Axios from "../WebClient";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
};

export const GetIncomingMonazamRequests = (
  setRequests,
  setLoading,
  setError
) => {
  Axios.get("/request/monazam/incoming", config)
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      // setError(err.message);
      console.log("Error: ", err);
    });
};

export const GetIncomingHGORequests = (setRequests, setLoading, setError) => {
  Axios.get("/request/hgo/incoming", config)
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      // setError(err.message);
      console.log("Error: ", err);
    });
};

export const GetOutgoingMonazamRequests = (
  setRequests,
  setLoading,
  setError
) => {
  Axios.get("/request/monazam/outgoing", config)
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      setRequests(res.data.data);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      console.log("Error: ", err);
    });
};

export const GetOutgoingMonazamFilteredRequests = (values, setRequests) => {
  let params = "";
  {
    !!values?.status
      ? (params += `status=${values.status}&`)
      : (params = params);
  }
  {
    !!values?.categoryType
      ? (params += `categoryType=${values.categoryType}&`)
      : (params = params);
  }
  {
    !!values?.currency
      ? (params += `currency=${values.currency}&`)
      : (params = params);
  }
  Axios.get(
    "/request/monazam/outgoing?" + params.substring(0, params.length - 1),
    config
  )
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      setRequests(res.data.data);
    })
    .catch((err) => {
      console.log("Error: ", err);
      ErrorToast(err?.response?.data?.message);
    });
};

export const GetOutgoingHGORequests = (setRequests, setLoading, setError) => {
  Axios.get("/request/hgo/outgoing", config)
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      setRequests(res.data.data);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      console.log("Error: ", err);
    });
};

export const CreateOutgoingRequests = (values, setError, handleCreate) => {
  Axios.post("/request/outgoing", values, config)
    .then((res) => {
      console.log("Create Outgoing requests response: ", res.data);
      handleCreate();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const CreateIncomingRequests = (values, setError, handleCreate) => {
  Axios.post("/request/incoming", values, config)
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      handleCreate();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err?.response?.data?.message);
    });
};

export const ApproveIncomingRequests = (id, setError, handleApprove) => {
  Axios.put("/request/incoming/approve/" + id, {}, config)
    .then((res) => {
      console.log("Approve request response: ", res.data);
      handleApprove();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const RejectIncomingRequests = (id, setError, handleReject) => {
  Axios.put("/request/incoming/reject/" + id, {}, config)
    .then((res) => {
      console.log("Reject request response: ", res.data);
      handleReject();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const ApproveOutcomingRequests = (id, setError, handleApprove) => {
  Axios.put("/request/outgoing/approve/" + id, {}, config)
    .then((res) => {
      console.log("Approve request response: ", res.data);
      handleApprove();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const RejectOutcomingRequests = (id, setError, handleReject) => {
  Axios.put("/request/outgoing/reject/" + id, {}, config)
    .then((res) => {
      console.log("Reject request response: ", res.data);
      handleReject();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};
